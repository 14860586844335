/* You can add global styles to this file, and also import other style files */
@import 'primeng/resources/themes/lara-light-blue/theme.css';
@import 'primeng/resources/primeng.css';
@import 'primeicons/primeicons.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;500;600;700&display=swap');

body {
  font-family: 'Inter', sans-serif;
  color: #111827;
}

.base {
  min-width: 900px;
  min-height: 600px;
}

.green-linier {
  background: linear-gradient(90.9deg, #51b688 0%, #178aad 100%);
}

.p-inputnumber {
  width: 100%;
}

.p-inputtext.input-search {
  height: 41px;
  border-radius: 0.5rem;
  width: 492px;
}

ngx-dropzone-preview {
  max-width: 300px!important;
  max-height: 300px!important;;
}

.count {
  .p-dropdown {
    height: 25px;
    border-radius: 0.5rem;
    width: 60px;

    .p-dropdown-label {
      font-size: 16px;
      vertical-align: middle;
      margin-top: -12px;
      color: black;
      font-weight: 400;
      margin-left: -5px;
    }

    .p-dropdown-trigger {
      margin-right: 5px;
      width: fit-content;
      span {
        font-size: 12px;
        color: black;
      }
    }
  }
}

.font-bold-500 {
  font-weight: 500;
}

.list-table {
  width: 100%;
  
  tr {
    height: 80px;
    border-bottom-width: 1px;

    th {
      font-weight: 700;
      font-size: 14px;
    }
    td {
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      color: black;
      min-width: 200px;
    }
  }
}

p-inputmask {
  .p-inputtext {
    height: 48px;
    width: 100%;
  }
}

.p-inputtext.form-create {
  height: 48px;
}

.bg_green_transparant {
  background: rgba(107, 193, 162, 0.5);
}

.p-datepicker-header {
  height: 40px!important;
}

.p-datepicker-calendar {
  tr {
    height: 40px!important;
    border: none;
  }
}


h1 {
  display: block;
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

h2 {
  display: block;
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

h3 {
  display: block;
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

h4 {
  display: block;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

h5 {
  display: block;
  font-size: .83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

h6 {
  display: block;
  font-size: .67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}